import React, { useState, useEffect, useRef } from "react";
import { api_base, loadUsers, updateUser, addUser } from "../services/consts";
import OurHeader from "./OurHeader";
import $ from "jquery";

function ManageUsers(props) {
  const [users, setUsers] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSearchesModal, setShowSearchesModal] = useState(false);
  const [userMode, setUserMode] = useState("edit");
  const [featuredUser, setFeaturedUser] = useState({});
  const [userSearches, setUserSearches] = useState([]);
  const [imagePath, setImagePath] = useState(null);

  const deleteUser = (user) => {
    fetch(api_base + "/searchapp/users/remove", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({ user_id: user._id }),
    })
      .then((res) => {
        if (res) {
          return res;
        }
        return null;
      })
      .then((data) => {
        setShowToast(true);
        loadUsers((data) => setUsers(data));
      });
  };

  const loadSearches = (username) => {
    console.log("Loading users");

    fetch(api_base + "/searchapp/searches/get-by-user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({ username: username }),
    })
      .then((res) => {
        if (res) {
          return res.json();
        }
        return null;
      })
      .then((data) => {
        console.log(data);
        setUserSearches(data);
        setShowSearchesModal(true);
      });
  };

  const editUser = (user) => {
    setShowModal(true);
    setUserMode("edit");
    setFeaturedUser(user);
  };

  const doAddUser = () => {
    setShowModal(true);
    setUserMode("add");
    setFeaturedUser({});
  };

  const saveUser = () => {
    if (userMode === "edit") {
      updateUser(featuredUser);
      window.setTimeout(() => {
        loadUsers((data) => setUsers(data));
      }, 300);
      setShowModal(false);
      setUserMode("add");
    } else {
      addUser(featuredUser);
      setUserMode("add");
      window.setTimeout(() => {
        loadUsers((data) => setUsers(data));
      }, 300);
      setShowModal(false);
    }
  };

  const handlePwdChange = (evt) => {
    //console.log("handling change", evt.target.value);
    const pwd = evt.target.value;
    const clonedUser = JSON.parse(JSON.stringify(featuredUser));
    clonedUser.password = pwd;
    console.log(clonedUser);
    setFeaturedUser(clonedUser);
  };

  const handleUsernameChange = (evt) => {
    //console.log("handling change", evt.target.value);
    const username = evt.target.value;
    const clonedUser = JSON.parse(JSON.stringify(featuredUser));
    clonedUser.username = username;
    console.log(clonedUser);
    setFeaturedUser(clonedUser);
  };

  const handleCustomerNameChange = (evt) => {
    //console.log("handling change", evt.target.value);
    const customer_name = evt.target.value;
    const clonedUser = JSON.parse(JSON.stringify(featuredUser));
    clonedUser.customer_name = customer_name;
    console.log(clonedUser);
    setFeaturedUser(clonedUser);
  };

  const handleCompanyNameChange = (evt) => {
    //console.log("handling change", evt.target.value);
    const company_name = evt.target.value;
    const clonedUser = JSON.parse(JSON.stringify(featuredUser));
    clonedUser.company_name = company_name;
    console.log(clonedUser);
    setFeaturedUser(clonedUser);
  };

  const handleOffsetChange = (evt) => {
    //console.log("handling change", evt.target.value);
    const offset = evt.target.value;
    const clonedUser = JSON.parse(JSON.stringify(featuredUser));
    clonedUser.offset = offset;
    console.log(clonedUser);
    setFeaturedUser(clonedUser);
  };

  const handleUserimagechange = (imgPath) => {
    //console.log("handling change", evt.target.value);
    // const offset = evt.target.value;
    const clonedUser = JSON.parse(JSON.stringify(featuredUser));
    clonedUser.image = imgPath;
    console.log(clonedUser);
    setFeaturedUser(clonedUser);
  };

  const setOffsetType = (offsetType) => {
    console.log("offset type", offsetType);
    featuredUser.offsetType = offsetType;
    setFeaturedUser(JSON.parse(JSON.stringify(featuredUser)));
  };

  useEffect(() => {
    loadUsers((data) => setUsers(data));
  }, []);

  const offsetTypes = {
    perc: "Percentage",
    fixed: "Fixed",
  };

  const ref = useRef(null);

  const Input = (props) => (
    <input type="file" name="file-input" multiple {...props} />
  );

  const test = (e) => {
    const file = e.target.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      console.log(reader.result);
      uploadImage(reader.result);
    };
  };

  const uploadImage = async (imageData) => {
    // console.log("uploading file", base64str);
    // const formData = new FormData();
    // formData.append("image", {
    //   uri: base64str,
    //   name: "my_photo.jpg",
    //   type: "image/jpg",
    // });
    // formData.append("Content-Type", "image/jpg");

    var formData = new FormData();
    formData.append("image", imageData);

    const test = await fetch(
      "http://optiqalthemes.com/upload-images/uploadfile.php",
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "no-cors", // no-cors, *cors, same-origin
        // headers: {
        //   "Content-Type": "application/json",
        // },
        body: formData,
      }
    )
      .then((response) => response.text())
      .then((text) => console.log(text));

    $.post(
      "http://optiqalthemes.com/upload-images/uploadfile.php",
      { image: imageData },
      (data) => {
        setImagePath("http://optiqalthemes.com/upload-images/" + data);
        handleUserimagechange("http://optiqalthemes.com/upload-images/" + data);
      }
    );

    console.log("test", test);
  };

  return (
    <div>
      <OurHeader history={props.history}></OurHeader>

      <div class="container">
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <button
            class="btn btn-success pull-right"
            onClick={() => doAddUser()}
          >
            ADD USER
          </button>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Username</th>

              <th scope="col">Offset Type</th>
              <th scope="col">Offset</th>
              <th scope="col">Number of Searches</th>
            </tr>
          </thead>
          <tbody>
            {users.map((u) => {
              return (
                <tr key={u._id}>
                  <td>{u.customer_name}</td>
                  <td>{u.username}</td>

                  <td>{offsetTypes[u.offsetType]}</td>
                  <td>
                    {u.offsetType === "fixed" && <span>$</span>}
                    {u.offset}
                    {u.offsetType === "perc" && <span>%</span>}
                  </td>
                  {u.searches ? <td>{u.searches}</td> : <td>0</td>}
                  <td>
                    <button
                      class="btn btn-danger"
                      onClick={() => deleteUser(u._id)}
                    >
                      DELETE
                    </button>
                  </td>
                  <td>
                    <button
                      class="btn btn-primary"
                      onClick={() => loadSearches(u.username)}
                    >
                      SEARCHES
                    </button>
                  </td>
                  <td>
                    <button class="btn btn-primary" onClick={() => editUser(u)}>
                      EDIT
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {showModal && (
          <div class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Add User</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setShowModal(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <form>
                    <div class="form-group">
                      <label for="exampleInputEmail1">Username</label>
                      <input
                        type="text"
                        value={featuredUser.username}
                        onChange={handleUsernameChange}
                        class="form-control"
                      />
                    </div>
                    <div class="form-group">
                      <label for="exampleInputPassword1">Password</label>
                      <input
                        type="text"
                        value={featuredUser.password}
                        onChange={handlePwdChange}
                        class="form-control"
                      />
                    </div>
                    <div class="form-group">
                      <Input onChange={test} />
                      {imagePath && (
                        <img src={imagePath} style={{ maxHeight: 100 }} />
                      )}
                    </div>
                    <div class="form-group">
                      <label for="exampleInputEmail1">Customer Name</label>
                      <input
                        type="text"
                        value={featuredUser.customer_name}
                        onChange={handleCustomerNameChange}
                        class="form-control"
                      />
                    </div>
                    <div class="form-group">
                      <label for="exampleInputEmail1">Company Name</label>
                      <input
                        type="text"
                        value={featuredUser.company_name}
                        onChange={handleCompanyNameChange}
                        class="form-control"
                      />
                    </div>

                    <div class="form-group">
                      <div class="btn-group" role="group">
                        {featuredUser.offsetType === "perc" ? (
                          <button type="button" class="btn btn-primary">
                            Percentage
                          </button>
                        ) : (
                          <button
                            //onClick={}
                            type="button"
                            onClick={() => setOffsetType("perc")}
                            class="btn btn-secondary"
                          >
                            Percentage
                          </button>
                        )}

                        {featuredUser.offsetType === "fixed" ? (
                          <button type="button" class="btn btn-primary">
                            Fixed
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() => setOffsetType("fixed")}
                            class="btn btn-secondary"
                          >
                            Fixed
                          </button>
                        )}
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="exampleInputEmail1">Offset</label>
                      <input
                        value={featuredUser.offset}
                        onChange={handleOffsetChange}
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </form>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    onClick={() => saveUser()}
                    class="btn btn-primary"
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {showSearchesModal && (
          <div class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">User Searches</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setShowSearchesModal(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div>
                    <table>
                      <thead>
                        <tr>
                          <th scope="col">Date</th>
                          <th scope="col">Username</th>
                          <th scope="col">OEM</th>
                        </tr>
                      </thead>
                      {userSearches.map((u) => {
                        return (
                          <tr>
                            <td>{u.date}</td>
                            <td>{u.username}</td>
                            <td>{u.oem}</td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => setShowSearchesModal(false)}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    onClick={() => saveUser()}
                    class="btn btn-primary"
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ManageUsers;
