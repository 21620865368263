export const defaultSettings = {
  percup: 10,
  markup: 1,
  username: "",
};

export const api_base = "https://optiqalapis.com";

export const app_name = "Search App";

export const login = (username, password, callback) => {
  console.log(username, password);
  fetch(
    api_base + "/searchapp/user/login",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        username,
        password,
      }),
    },
    (res) => console.log(res)
  )
    .then((res) => {
      if (res) {
        return res.json();
      }
      return null;
    })
    .then((data) => {
      if (data.username === "not_found") {
        callback("not_found");
      } else {
        console.log(data);
        callback(data);
      }
      //callback(data);
      return data;
    });
};

export const loadUsers = (callback) => {
  console.log("Loading users");

  fetch(api_base + "/searchapp/users/getAll", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    //body: JSON.stringify({ user })
  })
    .then((res) => {
      if (res) {
        return res.json();
      }
      return null;
    })
    .then((data) => {
      console.log(data);
      callback(data);
    });
};

export const updateUser = (user) => {
  console.log("updating user", user);
  fetch(api_base + "/searchapp/users/update", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify({
      user: user,
    }),
  })
    .then((res) => {
      if (res) {
        return res.json();
      }
      return null;
    })
    .then((data) => {
      console.log(data);
    });
};

export const updateUserSearches = (user) => {
  console.log("updating user", user);
  fetch(api_base + "/searchapp/users/searches/update", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify({
      user_id: user._id,
    }),
  })
    .then((res) => {
      if (res) {
        return res.json();
      }
      return null;
    })
    .then((data) => {
      console.log(data);
    });
};

export const addUser = (user) => {
  console.log("adding user", user);
  fetch(api_base + "/searchapp/users/put", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify({
      user: user,
    }),
  })
    .then((res) => {
      if (res) {
        return res.json();
      }
      return null;
    })
    .then((data) => {
      console.log(data);
    });
};
