import {
  defaultSettings,
  api_base,
  updateUserSearches,
  login,
} from "../services/consts.js";

import React, { useState, useEffect } from "react";
import OurHeader from "./OurHeader";

function Search(props) {
  const [partno, setpartno] = useState("");
  const [searches, setSearches] = useState([]);
  const [user, setUser] = useState(defaultSettings);
  const [showModal, setShowModal] = useState(false);
  const [featured, setFeatured] = useState({});
  const [mode, setMode] = useState("searching");
  const [isAdmin, setIsAdmin] = useState(false);
  const [coords, setCoords] = useState({});

  const parts = [];

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }

  function showPosition(position) {
    console.log(position);
    setCoords(position.coords);
  }

  const registerSearch = (username, oem, price) => {
    const user = JSON.parse(localStorage["userinfo"]);
    console.log("registering search", coords);

    if (user.searches === undefined) {
      user.searches = 1;
    } else {
      user.searches += 1;
    }
    updateUserSearches(user);
    //saveUser(user);
    fetch(api_base + "/searchapp/searches/put", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        search: {
          username: user.username,
          oem: oem,
          date: new Date(),
          coords: { latitude: coords.latitude, longitude: coords.longitude },
          price: price,
        },
      }),
    })
      .then((res) => {
        if (res) {
          return res.json();
        }
        return null;
      })
      .then((data) => {
        console.log(data);
        setSearches(data);
      });
  };

  const search = (val) => {
    const searchnumber = val.target.value;

    console.log("Search", searchnumber);

    let userinfo = JSON.parse(localStorage["userinfo"]);
    console.log("GOT USER INFO IN SEARCH", userinfo);

    const payload = {
      username: userinfo.username,
      password: userinfo.password,
      oem: searchnumber,
    };

    setpartno(searchnumber);

    console.log(payload);

    fetch(api_base + "/searchapp/pricelist/search", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        if (res) {
          return res.json();
        }
        return null;
      })
      .then((data) => {
        console.log(data);
        if (localStorage["usersettings"] !== undefined) {
          const settings = JSON.parse(localStorage["usersettings"]);
          console.log("got settings", settings.offset);
          for (let row of data) {
            console.log(row.price);
            if (settings.offsetType === "perc") {
              row.price = (
                parseFloat(row.price) * parseFloat(settings.offset)
              ).toFixed(2);
            } else {
              row.price = (
                parseFloat(row.price) + parseFloat(settings.offset)
              ).toFixed(2);
            }
          }
        }
        setSearches(data);
      });
  };

  const saveUser = (user) => {
    if (localStorage["userinfo"]) {
      localStorage["userinfo"] = JSON.stringify(user);
      console.log("user", user);
    }
  };

  const showItem = (item) => {
    setFeatured(item);
    registerSearch(user.username, item.number, item.price);
    setMode("details");
  };

  const loadParts = () => {};

  const logout = () => {
    localStorage.removeItem("userinfo");
    props.history.push("/home");
  };

  const newSearch = () => {
    window.location.reload();
  };

  useEffect(() => {
    loadParts();
    getLocation();
    let userinfo = JSON.parse(localStorage["userinfo"]);
    setUser(userinfo);
  }, []);

  const renderSearch = () => {
    return (
      <div>
        <OurHeader history={props.history}></OurHeader>
        <div className="container" style={{ marginTop: "40px" }}>
          {/* {JSON.stringify(user)} */}
          {user.image && (
            <img
              style={{ maxHeight: "75px", marginBottom: "10px" }}
              src={user.image}
            ></img>
          )}
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Search Parts"
              aria-label="Search Parts"
              aria-describedby="button-addon2"
              value={partno}
              onInput={search}
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary"
                type="button"
                id="button-addon2"
              >
                Search
              </button>
            </div>
          </div>

          {searches.map((u) => {
            return (
              <button
                type="button"
                class="btn btn-primary btn-lg btn-block"
                onClick={() => showItem(u)}
              >
                {u.number}
              </button>
            );
          })}
        </div>
      </div>
    );
  };

  const renderDetails = () => {
    return (
      <div>
        <OurHeader history={props.history}></OurHeader>
        <div style={{ marginTop: "40px" }}>
          <div className="container">
            {featured.image1 && (
              <img
                style={{ maxWidth: "300px" }}
                src={"http://optiqalthemes.com/images/" + featured.image1}
              />
            )}
            {featured.image2 && (
              <img
                style={{ maxWidth: "300px" }}
                src={"http://optiqalthemes.com/images/" + featured.image2}
              />
            )}
            {featured.image3 && (
              <img
                style={{ maxWidth: "300px" }}
                src={"http://optiqalthemes.com/images/" + featured.image3}
              />
            )}
            <h3 style={{ textAlign: "center" }}>Part No: {featured.number}</h3>
            <h4 style={{ textAlign: "center" }}>Price: ${featured.price}</h4>

            <button
              type="button"
              class="btn btn-primary btn-lg btn-block"
              onClick={newSearch}
            >
              New Search
            </button>
          </div>
        </div>
      </div>
    );
  };

  if (mode === "searching") {
    return renderSearch();
  } else {
    return renderDetails();
  }
}

export default Search;
